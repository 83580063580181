import * as React from "react";
import { BaseFieldProps } from './types';
import { Editor } from '@tinymce/tinymce-react';


// Kendisine gelen Property ismine ve değerine göre HTML Editor render eder ve değerleri basar.
export default class HTMLField extends React.Component<BaseFieldProps> {
    componentDidMount() {
        window.addEventListener("mousedown", this.onStopImmediatePropagation)
    }

    componentWillUnmount() {
        window.removeEventListener("mousedown", this.onStopImmediatePropagation)

    }

    onStopImmediatePropagation = (e: any) => {
        const propertyModal = document.querySelectorAll(".full-modal").length ? document.querySelectorAll(".full-modal")[1] : null;
        if (e.target.classList?.contains("tox-textarea") || e.target.classList?.contains("tox-textfield")) {
            if (propertyModal && !propertyModal?.classList?.contains("d-none")) {
                propertyModal?.classList.add("d-none");
            }
        }
        else {
            propertyModal?.classList.remove("d-none");
        }
    }

    handleEditorChange = (content: string) => {
        this.props.onChange(content);
    }

    render() {
        if (this.props.property.conditionalHide && this.props.property.conditionalHide(this.props)) return null;

        return (
            <div className="property-item row">
                <div title={this.props.property.text || this.props.property.name} className="property-text">
                    <div className="title">{this.props.property.text || "HTML İçerik"}</div>
                    <div className="sub-title">Sağ taraftan bir HTML içerik eklemesi yapabilirsiniz. Yazılarınızın boyutlarını, stillerini ayarlayabilir, içeriğinize görsel ekleyebilir, mizampaj ayarlamaları yapabilirsiniz.</div>
                </div>
                <div className="col">
                    <Editor
                        value={this.props.value}
                        apiKey="sd2i9k9pyhf0ogxu9se1jgsjtvaeam4m8noyzxfszof8rt0i"
                        init={{
                            height: 400,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            content_style: "body { font-size: 13px }",
                            toolbar:
                                'anchor | link | undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help | code'
                        }}
                        onEditorChange={this.handleEditorChange}
                    />
                </div>
            </div>
        );
    }
}