import { Editor } from '@tinymce/tinymce-react';
import { Component } from 'react';
import { BaseMetaFieldsProps } from './types';

export default class MetaEditor extends Component<BaseMetaFieldsProps<string>> {

    handleEditorChange = (content: string) => {
        this.props.onChange(content);
    }

    render() {
        return (
            <Editor
                value={this.props.meta.data as string || ""}
                apiKey="sd2i9k9pyhf0ogxu9se1jgsjtvaeam4m8noyzxfszof8rt0i"
                init={{
                    height: 250,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    content_style: "body { font-size: 13px }",
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help | code'
                }}
                onEditorChange={this.handleEditorChange}
            />
        )
    }
}
